import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout/layout"


const MainPage = () => {
  
  return (
    <>
     <Layout>
      <SEO title="Tworcy Emigracji" />
    </Layout>
    </>
  )
}

export default MainPage
